<template>
  <div ref="conteudodash" class="conteudoDash">
    <div v-if="carregando == true" class="carregando">
      <!--img width="100" src="https://bboneapp.s3.amazonaws.com/evo-white.png" /-->
      <br />
      <br />
      <br />Carregando.. Aguarde
    </div>
    <VuePullRefresh
      v-if="exibeDetalhe == false"
      :userSelect="false"
      :on-refresh="onRefresh"
      :config="config"
      :showScrollBar="true"
    >
      <div v-if="decoded.tipo == 'Consultor'">
        <FiltroProposta
          v-show="pageSlide !== 4"
          :aberta="statusParaAberta"
          @filtroStatus="filtraStatus"
          @FiltroCotacoesEmitido="filtraCotacoes"
        ></FiltroProposta>
        <FiltroPreCotacao
          v-show="pageSlide === 4"
          @filtroStatusPreCotacao="filtrarStatusPreCotacao"
          :semFiltro="statusParaSemFiltro"
          @FiltroPreCotacoesEmitido="filtraPreCotacoes"
        ></FiltroPreCotacao>
        <b-input-group prepend="Meu Link" class="my-3">
          <b-form-input
            v-model="meuLink"
            id="Desconto"
            name="Desconto"
          ></b-form-input>

          <b-input-group-append>
            <button
              @click="copiarText"
              type="button"
              class="btn btn-dark btn-copy-link"
            >
              <i class="fa fa-copy"></i></button
          ></b-input-group-append>
        </b-input-group>

        <h3 class="ml-2">
          Produtividade:
          <span class="text-secondary">{{ verificaTipoGrafico() }}</span>
        </h3>
        <div class="text-left boxConsultor">
          <carousel
            @pageChange="changePage"
            :autoplay="false"
            :perPageCustom="[[0, 1]]"
            pagniationPosition="bottom-overlay"
            :paginationPadding="2"
            paginationActiveColor="#000000"
            paginationColor="#CCCCCC"
            :navigateTo="pageSlideExterna"
          >
            <slide>
              <slideGrafico
                :options="options"
                :series="series"
                :isLoad="isLoad"
                :total="qtdOrigem.network"
                :indexGrafico="0"
                @FiltraLegenda="filtra"
              ></slideGrafico>
            </slide>
            <slide>
              <slideGrafico
                :options="options"
                :series="series2"
                :isLoad="isLoad"
                :total="qtdOrigem.site"
                :indexGrafico="1"
                @FiltraLegenda="filtra"
              ></slideGrafico>
            </slide>
            <slide>
              <slideGrafico
                :options="options"
                :series="series3"
                :isLoad="isLoad"
                :total="qtdOrigem.indicacao"
                :indexGrafico="2"
                @FiltraLegenda="filtra"
              ></slideGrafico>
            </slide>
            <slide>
              <slideGrafico
                :options="options"
                :series="series4"
                :isLoad="isLoad"
                :total="qtdOrigem.b2c"
                :indexGrafico="3"
                @FiltraLegenda="filtra"
              ></slideGrafico>
            </slide>
            <slide>
              <slideGrafico
                :options="optionsPreCotacao"
                :series="seriesPreCotacao"
                :isLoad="isLoad"
                :total="qtdOrigem.preCotacao"
                :indexGrafico="4"
                @FiltraLegenda="filtra"
              ></slideGrafico>
            </slide>
          </carousel>
        </div>
        <div v-if="pageSlide !== 4" class="listaCotacao">
          <ListCotacoes
            @DetalheCotacao="DetalheCotacao"
            :cotacoes="cotacoesShow"
          ></ListCotacoes>
          <center>
            <b-button v-if="qtdTotal > cotacoes.length" @click="buscarMais()"
              >Buscar +</b-button
            >
          </center>
        </div>
        <div v-if="pageSlide === 4" class="listaCotacao">
          <ListPreCotacoes
            @DetalheCotacao="DetalheCotacao"
            :cotacoes="preCotacao"
          ></ListPreCotacoes>
          <center>
            <b-button
              v-if="qtdOrigem.preCotacao > preCotacao.length"
              @click="buscarMaisPreCotacoes()"
              >Buscar +</b-button
            >
          </center>
        </div>
      </div>
      <div v-else>
        <div v-if="dash == false">
          <b-row>
            <b-col cols="2">
              <img
                @click="retornaDash()"
                src=" https://bboneapp.s3.amazonaws.com/mobile/back.png"
              />
            </b-col>
            <b-col>
              <h3 class="m-4 text-center">
                Propostas:
                <span class="text-secondary">Em Aberto</span>
              </h3>
            </b-col>
          </b-row>

          <div v-if="pageSlide !== 4" class="listaCotacao">
            <ListCotacoes
              @DetalheCotacao="DetalheCotacao"
              :cotacoes="cotacoesShow"
            ></ListCotacoes>
          </div>
        </div>
        <div v-else>
          <div class="carroussel container" style="margin-top: 10px">
            <carousel
              :autoplay="false"
              :perPageCustom="[[0, 1]]"
              pagniationPosition="bottom-overlay"
              :paginationPadding="2"
              paginationActiveColor="#000000"
              paginationColor="#CCCCCC"
              v-if="veiculosAtivos.length > 0 || veiculosInativos.length > 0"
            >
              <slide>
                <div class="boxCarro p-4">
                  <b-row>
                    <b-col> <i class="fa fa-car text-success"></i></b-col>
                    <b-col class="text-right titulo"
                      >Veiculos<span>Ativos</span></b-col
                    >
                  </b-row>
                  <b-row>
                    <b-col cols="5" class="quantidade borderSuccess"
                      >{{ veiculosAtivos.length }}
                    </b-col>
                  </b-row>
                </div>
              </slide>
              <slide>
                <div class="boxCarro p-4">
                  <b-row>
                    <b-col> <i class="fa fa-car text-danger"></i></b-col>
                    <b-col class="text-right titulo"
                      >Veiculos<span>Inativos</span></b-col
                    >
                  </b-row>
                  <b-row>
                    <b-col cols="5" class="quantidade borderDanger"
                      >{{ veiculosInativos.length }}
                    </b-col>
                  </b-row>
                </div>
              </slide>
              <slide>
                <div class="boxCarro p-4">
                  <b-row>
                    <b-col> <i class="fa fa-car text-warning"></i></b-col>
                    <b-col class="text-right titulo"
                      >Veiculos<span>Inadimplentes</span></b-col
                    >
                  </b-row>
                  <b-row>
                    <b-col cols="5" class="quantidade borderWarning"
                      >{{ veiculosInadimplentes.length }}
                    </b-col>
                  </b-row>
                </div>
              </slide>
              <slide>
                <div class="boxCarro p-4">
                  <b-row>
                    <b-col> <i class="fa fa-car text-dark"></i></b-col>
                    <b-col class="text-right titulo"><span>Outros</span></b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="5" class="quantidade borderDark"
                      >{{ veiculosOutros.length }}
                    </b-col>
                  </b-row>
                </div>
              </slide>
            </carousel>
          </div>
          <div
            class="carroussel cobrancas"
            style="margin-top: 10px"
            v-if="listaCobrancas.length > 0"
          >
            <div class="text-right mt-2">
              <router-link class="btn btn-dark btn-round mr-4" :to="'Boletos'"
                ><i class="fa fa-list mr-3"></i
                ><i class="fa fa-dollar mr-3"></i>Listagem de
                Cobranças</router-link
              >
            </div>
            <div class="container">
              <carousel
                class="mt-3"
                :autoplay="false"
                :perPageCustom="[[0, 1]]"
                pagniationPosition="bottom-overlay"
                :paginationPadding="2"
                paginationActiveColor="#000000"
                paginationColor="#fff"
              >
                <slide>
                  <div class="boxCarro p-4">
                    <b-row>
                      <b-col> <i class="fa fa-money text-warning"></i></b-col>
                      <b-col class="text-right titulo"
                        >Cobranças<span>Em Aberto</span></b-col
                      >
                    </b-row>
                    <b-row>
                      <b-col cols="5" class="quantidade borderWarning"
                        >{{ cobrancasEmAberto.length }}
                      </b-col>
                    </b-row>
                  </div>
                </slide>
                <slide>
                  <div class="boxCarro p-4">
                    <b-row>
                      <b-col> <i class="fa fa-money text-success"></i></b-col>
                      <b-col class="text-right titulo"
                        >Cobranças<span>Finalizadas</span></b-col
                      >
                    </b-row>
                    <b-row>
                      <b-col cols="5" class="quantidade borderSuccess"
                        >{{ cobrancasFinalizadas.length }}
                      </b-col>
                    </b-row>
                  </div>
                </slide>
                <slide>
                  <div class="boxCarro p-4">
                    <b-row>
                      <b-col> <i class="fa fa-money text-danger"></i></b-col>
                      <b-col class="text-right titulo"
                        >Cobranças<span>Vencidas</span></b-col
                      >
                    </b-row>
                    <b-row>
                      <b-col cols="5" class="quantidade borderDanger"
                        >{{ cobrancasVencidas.length }}
                      </b-col>
                    </b-row>
                  </div>
                </slide>
              </carousel>
            </div>
            <!--b-row>
            <b-col
              @click="
                () => {
                  this.dash = false;
                }
              "
              cols="6"
              class="bg-light text-center box"
            >
              <div class="iconeChart">
                <img
                  src=" https://bboneapp.s3.amazonaws.com/mobile/icone-calculadora.png"
                />
              </div>
              <span>Cotações</span>
            </b-col>
            <b-col
              v-if="decoded.dataAtivacao || decoded.codigoAssociadoSga"
              cols="6"
              class="bg-light text-center box"
            >
              <router-link class="link-black" :to="'/Veiculos'">
                <div class="iconeChart">
                  <img
                    src=" https://bboneapp.s3.amazonaws.com/mobile/car.png"
                  />
                </div>
                <span>Veículos</span>
              </router-link>
            </b-col>
            <b-col
              v-if="decoded.dataAtivacao || decoded.codigoAssociadoSga"
              cols="6"
              class="bg-light text-center box"
            >
              <router-link class="link-black" :to="'/Boletos'">
                <div class="iconeChart">
                  <img
                    src=" https://bboneapp.s3.amazonaws.com/mobile/icone-boleto2.png"
                  />
                </div>
                <span>Boletos</span>
              </router-link>
            </b-col>
            <b-col
              v-if="decoded.dataAtivacao || decoded.codigoAssociadoSga"
              cols="6"
              class="bg-light text-center box"
            >
              <router-link class="link-black" :to="'/CartaoAssociado'">
                <div class="iconeChart">
                  <img
                    src=" https://bboneapp.s3.amazonaws.com/mobile/cartaoicone.png"
                  />
                </div>
                <span>Cartão Associado</span>
              </router-link>
            </b-col>
            <b-col
              v-if="decoded.dataAtivacao || decoded.codigoAssociadoSga"
              cols="6"
              class="bg-light text-center box"
            >
              <router-link class="link-black" :to="'/Regulamento'">
                <div class="iconeChart">
                  <img
                    src=" https://bboneapp.s3.amazonaws.com/mobile/regulamento.png"
                  />
                </div>
                <span>Regulamento</span>
              </router-link>
            </b-col>
            <b-col
              v-if="decoded.dataAtivacao || decoded.codigoAssociadoSga"
              cols="6"
              class="bg-light text-center box"
            >
              <router-link class="link-black" :to="'/Termos'">
                <div class="iconeChart">
                  <img
                    src=" https://bboneapp.s3.amazonaws.com/mobile/boleto.png"
                  />
                </div>
                <span>Termos</span>
              </router-link>
            </b-col>

            <b-col cols="6" class="bg-light text-center box">
              <div class="iconeChart">
                <img src=" https://bboneapp.s3.amazonaws.com/mobile/boleto.png" />
              </div>
              <span>Atualizar Boleto</span>
            </b-col>
          </b-row-->
          </div>
        </div>
      </div>
    </VuePullRefresh>
    <div v-else>
      <DetalheCotacao
        @FechaDetalhe="FechaDetalhe"
        :itemDetalhe="itemDetalhe"
        :selecionouCotacao="selecionouCotacao"
        @DetalheCotacao="DetalheCotacao"
      ></DetalheCotacao>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
import Metas from './Metas';
import FiltroProposta from './FiltroProposta';
import FiltroPreCotacao from './FiltroPreCotacao.vue';
import VuePullRefresh from 'vue-pull-refresh';
import { Carousel, Slide } from 'vue-carousel';
import slideGrafico from './SlideGrafico';
import ListCotacoes from './ListCotacoes';
import ListPreCotacoes from './listPreCotacoes.vue';
import DetalheCotacao from './DetalheProposta';
import service from '../services';

export default {
  name: 'DashBoard',
  props: ['irPage'],
  watch: {
    irPage() {
      if (this.irPage === 4) {
        this.pageSlideExterna = this.irPage;
      }
    },
    pageSlide() {
      if (this.pageSlide !== 4 && this.pageSlideExterna === 4) {
        this.pageSlideExterna = this.pageSlide;
        this.$emit('resetarPage');
      }
    },
  },
  data() {
    return {
      styleConteudo: {
        marginTop: '150px',
        height: '100%',
      },
      dash: true,
      meuLink: '',
      selecionouCotacao: false,
      carregando: false,
      decoded: {},
      exibeDetalhe: false,
      cotacoes: [],
      cotacoesShow: [],
      cotacoesNetwork: [],
      cotacoesSite: [],
      cotacoesIndicacao: [],
      cotacoesB2C: [],
      preCotacao: [],
      veiculos: [],
      veiculosAtivos: [],
      veiculosInativos: [],
      veiculosInadimplentes: [],
      veiculosOutros: [],
      listaCobrancas: [],
      cobrancasEmAberto: [],
      cobrancasFinalizadas: [],
      cobrancasVencidas: [],
      isLoad: false,
      isFiltro: false,
      pagina: 1,
      paginaPreCotacao: 1,
      series: [0, 0, 0],
      series2: [0, 0, 0],
      series3: [0, 0, 0],
      series4: [0, 0, 0],
      seriesPreCotacao: [0, 0],
      qtdOrigem: {
        b2c: 0,
        site: 0,
        network: 0,
        indicacao: 0,
        preCotacao: 0,
      },
      qtdTotal: 0,
      itemDetalhe: null,
      filtro: {
        status: [{ id: 1 }, { id: 3 }, { id: 5 }, { id: 6 }, { id: 7 }],
      },
      statusParaAberta: 0,
      statusParaSemFiltro: 0,
      filtroPreCotacaoAtivo: null,
      filtroCotacoes: {},
      filtroPreCotacoes: {},
      options: {
        colors: ['#28c76f', '#ff9f43', '#000080'],
        legend: {
          show: false,
          position: 'bottom',
          horizontalAlign: 'center',
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'middle',
          style: {
            fontSize: '8px',
          },
          formatter: function (val) {
            return val.toFixed(1);
          },
        },
        labels: ['Em Aberto', 'Aguardando Aprovação', 'Pendentes'],
      },
      optionsPreCotacao: {
        colors: ['#44C0F0', '#2F3666'],
        legend: {
          show: false,
          position: 'bottom',
          horizontalAlign: 'center',
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'middle',
          style: {
            fontSize: '8px',
          },
          formatter: function (val) {
            return val.toFixed(1);
          },
        },
        labels: ['Convertidas', 'Não Convertidas'],
      },
      pageSlide: 0,
      pageSlideExterna: 0,
      config: {
        startLabel: 'Arraste para recarregar os dados', // The text is displayed when you pull down
        readyLabel: 'Solte para recarregar', // The text that appears when the trigger distance is pulled down
        loadingLabel: 'Carregando...', // The text in the load
        doneText: 'Dados recarregados com sucesso', // Load the finished text
        failText: 'Não foi possível recarregar', // Load failed text
        loadedStayTime: 400, // Time to stay after loading ms
        stayDistance: 15, // Trigger the distance after the refresh
        triggerDistance: 30, // Pull down the trigger to trigger the distance
      },
    };
  },
  methods: {
    copiarText() {
      //O texto que será copiado
      const texto = this.meuLink;
      //Cria um elemento input (pode ser um textarea)
      let inputTest = document.createElement('input');
      inputTest.value = texto;
      //Anexa o elemento ao body
      document.body.appendChild(inputTest);
      //seleciona todo o texto do elemento
      inputTest.select();
      //executa o comando copy
      //aqui é feito o ato de copiar para a area de trabalho com base na seleção
      document.execCommand('copy');
      //remove o elemento
      document.body.removeChild(inputTest);
      this.$bvToast.toast(`Seu Link foi copiado para a área de Transfêrencia`, {
        title: 'Atenção!',
        solid: true,
        variant: 'success',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
    zeraArrayCotacoes() {
      this.cotacoesShow = [];
      this.cotacoesNetwork = [];
      this.cotacoesSite = [];
      this.cotacoesB2C = [];
      this.cotacoes = [];
      this.pagina = 1;
    },
    filtraStatus(idStatus) {
      this.zeraArrayCotacoes();
      /* console.log('filtrar por ', idStatus);
      this.cotacoesShow = [];
      this.cotacoesNetwork = [];
      this.cotacoesSite = [];
      this.cotacoesB2C = [];
      this.cotacoes = [];
      this.pagina = 1; */
      if (idStatus) this.filtro.status = [{ id: idStatus }];
      else
        this.filtro.status = [
          { id: 1 },
          { id: 3 },
          { id: 5 },
          { id: 6 },
          { id: 7 },
        ];
      this.buscaCotacoes();
    },
    filtraCotacoes(formFiltro) {
      const filtroCotacoes = {
        placa: formFiltro.placa,
        nomeAssociado: formFiltro.nome,
        cpfCnpjAssociado: formFiltro.cpfcnpj,
        emailAssociado: formFiltro.email,
        telefoneAssociado: formFiltro.telefone,
        idProposta: formFiltro.codigo,
      };
      this.filtro.status = [
        { id: 1 },
        { id: 3 },
        { id: 5 },
        { id: 6 },
        { id: 7 },
      ];
      this.statusParaAberta++;
      this.zeraArrayCotacoes();
      this.filtroCotacoes = filtroCotacoes;
      this.buscaCotacoes();
    },
    filtrarStatusPreCotacao(idStatus) {
      this.filtroPreCotacaoAtivo = idStatus;
      this.buscaPreCotacoes();
    },
    filtraPreCotacoes(formFiltro) {
      const filtroPreCotacoes = {
        placa: formFiltro.placa,
        nome: formFiltro.nome,
        email: formFiltro.email,
        celular: formFiltro.celular,
        codigo: formFiltro.codigo,
      };
      this.filtroPreCotacoes = filtroPreCotacoes;
      this.filtroPreCotacaoAtivo = null;
      this.statusParaSemFiltro++;
      this.buscaPreCotacoes(true);
    },
    retornaDash() {
      this.dash = true;
    },
    openDecoded() {
      const token = localStorage.getItem('user-token');
      if (token) {
        this.decoded = jwt.decode(token);
        if (this.decoded.tipo != 'Consultor') {
          this.styleConteudo.marginTop = '170px';
        } else {
          this.meuLink = `${window.location.origin.replace(
            'app',
            'adm',
          )}/novaProposta/${this.decoded.id_empresa}/${this.decoded.userName}`;
        }
        console.log(this.decoded);
      }
    },
    buscaVeiculos() {
      return new Promise((resolve, reject) => {
        //this.mockSwipeList[0].push(this.template2);
        if (this.decoded.tipo != 'Consultor') {
          this.veiculos = [];
          this.carregando = true;
          service.post('Veiculos', 'listarVeiculosDashApp', {}).then((res) => {
            console.log(res.data);
            this.veiculosAtivos = res.data.veiculosAtivos;
            this.veiculosInativos = res.data.veiculosInativos;
            this.veiculosInadimplentes = res.data.veiculosInadimplentes;
            this.veiculosOutros = res.data.veiculosOutros;
            this.carregando = false;
            resolve();
          });
        }
      });
    },
    buscaCobrancas() {
      return new Promise((resolve, reject) => {
        //this.mockSwipeList[0].push(this.template2);
        if (this.decoded.tipo != 'Consultor') {
          this.veiculos = [];
          this.carregando = true;
          service.getAll('Boletos', {}, 'listSga').then((res) => {
            console.log(res.data);
            this.listaCobrancas = res.data;
            this.cobrancasEmAberto = this.listaCobrancas.filter(
              (x) =>
                x.situacao_boleto == 'Em Aberto' ||
                x.situacao_boleto == 'ABERTO',
            );

            this.cobrancasFinalizadas = this.listaCobrancas.filter(
              (x) =>
                x.situacao_boleto == 'BAIXADO' ||
                x.situacao_boleto == 'Finalizado',
            );

            this.cobrancasVencidas = this.listaCobrancas.filter(
              (x) =>
                x.situacao_boleto != 'BAIXADO' &&
                x.situacao_boleto != 'CANCELADO' &&
                x.situacao_boleto != 'Finalizado' &&
                x.vencido == true,
            );
            this.carregando = false;
            resolve();
          });
        }
      });
    },
    buscarMais() {
      this.pagina = this.pagina + 1;
      this.buscaCotacoes();
    },
    buscarMaisPreCotacoes() {
      this.paginaPreCotacao = this.paginaPreCotacao + 1;
      this.buscaPreCotacoes();
    },
    buscaCotacoes() {
      return new Promise((resolve, reject) => {
        /*this.cotacoesShow = [];
        this.cotacoesNetwork = [];
        this.cotacoesSite = [];
        this.cotacoesB2C = [];*/
        this.carregando = true;
        //this.mockSwipeList[0].push(this.template2);
        var form = { ...this.decoded };
        form.status = this.filtro.status;
        if (this.decoded.tipo == 'Consultor') {
          form.consultor = [{ id: this.decoded.id_usuario }];
        } else {
          form.idProspect = [{ id: this.decoded.id_prospect }];
          form.status.push({ id: 4 });
        }
        form.pagina = this.pagina;
        form.qtdPorPagina = 25;
        form.appConsultor = true;
        delete form.placa;

        form = { ...form, ...this.filtroCotacoes };

        service.post('Cotacao', 'listarCotacoesFiltro', form).then((res) => {
          console.log(res.data);
          res.data.cotacoes.forEach((element) => {
            element.exibeDetalhe = false;
            if (this.decoded.tipo == 'Consultor') {
              this.cotacoes.push(element);
              if (element.origem == 'Network')
                this.cotacoesNetwork.push(element);
              if (element.origem == 'Site') this.cotacoesSite.push(element);
              if (element.origem == 'Indicação')
                this.cotacoesIndicacao.push(element);
              if (element.origem == 'B2C') this.cotacoesB2C.push(element);
            } else {
              if (element.nomeStatus != 'Recusada') {
                this.cotacoes.push(element);
                if (element.origem == 'Network')
                  this.cotacoesNetwork.push(element);
                if (element.origem == 'Site') this.cotacoesSite.push(element);
                if (element.origem == 'Indicação')
                  this.cotacoesIndicacao.push(element);
                if (element.origem == 'B2C') this.cotacoesB2C.push(element);
              }
            }
          });

          var qtdSite = res.data.qtdOrigem.find((x) => x.origem == 'Site');
          this.qtdOrigem.site = qtdSite ? qtdSite.qtd : 0;

          var qtdB2c = res.data.qtdOrigem.find((x) => x.origem == 'B2C');
          this.qtdOrigem.b2c = qtdB2c ? qtdB2c.qtd : 0;

          var qtdNetwork = res.data.qtdOrigem.find(
            (x) => x.origem == 'Network',
          );
          this.qtdOrigem.network = qtdNetwork ? qtdNetwork.qtd : 0;

          var qtdIndicacao = res.data.qtdOrigem.find(
            (x) => x.origem == 'Indicação',
          );
          this.qtdOrigem.indicacao = qtdIndicacao ? qtdIndicacao.qtd : 0;

          this.carregando = false;
          this.atualizaGraficos();
          var cotacoesAux = [];

          this.qtdTotal = res.data.qtd;

          /*if (this.cotacoesSite.length > 0)
            this.cotacoesShow = this.cotacoesSite;

          if (this.cotacoesIndicacao.length > 0)
            this.cotacoesShow = this.cotacoesIndicacao;
          if (this.cotacoesNetwork.length > 0)
            this.cotacoesShow = this.cotacoesNetwork;
          */
          if (this.decoded.tipo == 'Consultor') {
            this.cotacoesShow = this.cotacoesNetwork;
          } else {
            this.cotacoesShow = [
              ...this.cotacoesNetwork,
              ...this.cotacoesIndicacao,
              ...this.cotacoesSite,
              ...this.cotacoesB2C,
            ];
          }
          resolve();
        });
      });
    },
    buscaPreCotacoes() {
      if (this.decoded.tipo !== 'Associado') {
        return new Promise((resolve, reject) => {
          let form = {
            idEmpresa: this.decoded.id_empresa,
            idRegional: this.decoded.id_regional,
            idUsuario: this.decoded.id_usuario,
            pagina: this.paginaPreCotacao,
            qtdPorPagina: 100,
            status: this.filtroPreCotacaoAtivo,
          };

          form = { ...form, ...this.filtroPreCotacoes };

          service
            .post('PreCotacao', 'listarPreCotacoesFiltro', form)
            .then((res) => {
              this.preCotacao = res.data.preCotacoes;
              this.qtdOrigem.preCotacao = res.data.qtdOrigemPC.qtd;
              this.atualizaGraficosPreCotacao();
            })
            .catch((e) => {
              console.log(e);
            });
        });
      }
    },
    atualizaGraficos() {
      //Network
      var qtdEmAberto = this.cotacoesNetwork.filter(
        (x) =>
          x.nomeStatus == 'Em Aberto' ||
          x.nomeStatus == 'Em Processo de Vistoria',
      ).length;
      var qtdEnviada = this.cotacoesNetwork.filter(
        (x) =>
          x.nomeStatus == 'Aguardando Aprovação' ||
          x.nomeStatus == 'Em Pré-Análise',
      ).length;
      var qtdPendente = this.cotacoesNetwork.filter(
        (x) => x.nomeStatus == 'Pendente',
      ).length;
      this.series = [qtdEmAberto, qtdEnviada, qtdPendente];

      //Site
      qtdEmAberto = this.cotacoesSite.filter(
        (x) =>
          x.nomeStatus == 'Em Aberto' ||
          x.nomeStatus == 'Em Processo de Vistoria',
      ).length;
      qtdEnviada = this.cotacoesSite.filter(
        (x) =>
          x.nomeStatus == 'Aguardando Aprovação' ||
          x.nomestatus == 'Em Pré-Análise',
      ).length;
      qtdPendente = this.cotacoesSite.filter(
        (x) => x.nomeStatus == 'Pendente',
      ).length;
      this.series2 = [qtdEmAberto, qtdEnviada, qtdPendente];

      //Indicacao
      qtdEmAberto = this.cotacoesIndicacao.filter(
        (x) =>
          x.nomeStatus == 'Em Aberto' ||
          x.nomeStatus == 'Em Processo de Vistoria',
      ).length;
      qtdEnviada = this.cotacoesIndicacao.filter(
        (x) =>
          x.nomeStatus == 'Aguardando Aprovação' ||
          x.nomeStatus == 'Em Pré-Análise',
      ).length;
      qtdPendente = this.cotacoesIndicacao.filter(
        (x) => x.nomeStatus == 'Pendente',
      ).length;
      this.series3 = [qtdEmAberto, qtdEnviada, qtdPendente];

      //Indicacao
      qtdEmAberto = this.cotacoesB2C.filter(
        (x) =>
          x.nomeStatus == 'Em Aberto' ||
          x.nomeStatus == 'Em Processo de Vistoria',
      ).length;
      qtdEnviada = this.cotacoesB2C.filter(
        (x) =>
          x.nomeStatus == 'Aguardando Aprovação' ||
          x.nomeStatus == 'Em Pré-Análise',
      ).length;
      qtdPendente = this.cotacoesB2C.filter(
        (x) => x.nomeStatus == 'Pendente',
      ).length;
      this.series4 = [qtdEmAberto, qtdEnviada, qtdPendente];
    },
    atualizaGraficosPreCotacao() {
      const qtdConvertidas = this.preCotacao.filter(
        (x) => x.status === 'Convertida',
      ).length;
      const qtdNaoConvertidas = this.preCotacao.filter(
        (x) => x.status === 'Não Convertida',
      ).length;
      this.seriesPreCotacao = [qtdConvertidas, qtdNaoConvertidas];
    },
    FechaDetalhe() {
      console.log('fecha');
      this.exibeDetalhe = false;
      this.selecionouCotacao = false;
    },
    DetalheCotacao(item) {
      this.exibeDetalhe = true;
      console.log(item);
      this.itemDetalhe = item;
      this.selecionouCotacao = true;
    },
    onRefresh: function () {
      // this.buscaCotacoes();
    },
    loadMore() {
      console.log('LoadMore');
    },
    validaStyle() {
      console.log(this.decoded);
      if (this.decoded.tipo == 'Consultor') {
        this.styleConteudo.marginTop = '150px';
      } else {
        this.styleConteudo.marginTop = '122px';
      }
    },
    limpaFiltro() {
      console.log('limpaFiltro', this.isFiltro);
      this.isFiltro = false;
    },
    filtra(obj) {
      console.log(obj);
    },
    changePage(obj) {
      this.pageSlide = obj;
      if (this.pageSlide == 0) this.cotacoesShow = this.cotacoesNetwork;

      if (this.pageSlide == 1) this.cotacoesShow = this.cotacoesSite;

      if (this.pageSlide == 2) this.cotacoesShow = this.cotacoesIndicacao;

      if (this.pageSlide == 3) this.cotacoesShow = this.cotacoesB2C;
    },
    verificaTipoGrafico() {
      if (this.pageSlide == 0) return 'Network do consultor';
      if (this.pageSlide == 1) return 'Cotações do Site';
      if (this.pageSlide == 2) return 'Cotações por Indicação';
      if (this.pageSlide == 3) return 'Cotações B2C';
      if (this.pageSlide == 4) return 'Pré Cotações';
    },
    irParaPreCotacao() {
      const page = this.$route.query.page;
      if (+page === 4) {
        this.pageSlideExterna = +page;
      }
    },
  },
  mounted() {
    this.openDecoded();
    this.buscaCotacoes();
    this.buscaPreCotacoes();
    this.buscaVeiculos();
    this.buscaCobrancas();
    this.validaStyle();
    this.irParaPreCotacao();
  },
  components: {
    VuePullRefresh,
    Slide,
    Carousel,
    slideGrafico,
    Metas,
    ListCotacoes,
    DetalheCotacao,
    FiltroProposta,
    FiltroPreCotacao,
    ListPreCotacoes,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.btn-round {
  border-radius: 10px !important;
  height: auto;
  padding: 10px;
}
h3 {
  font-size: 18px;
  text-transform: uppercase;
}
h3 span {
  font-size: 12px;
  margin-left: 10px;
}
.boxConsultor .VueCarousel {
  height: 185px !important;
}
.carroussel {
  padding: 5px;
}
.conteudoDash {
  background-color: #dbdbdb;
  position: relative;
  z-index: 998;
}
.listaCotacao {
  font-size: 9pt;
  padding-bottom: 110px;
}
.pull-down-container {
  overflow-x: hidden !important;
}
.iconeChart {
  width: 60px;
  height: 60px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
  background-color: #999;
  border-radius: 50%;
  padding: 10px;
  margin: 10px 33%;
}
.iconeChart img {
  width: 100%;
}
.cobrancas {
  background-color: rgb(204, 203, 203);
}
.box {
  box-model: border-box;
  border: 10px solid transparent;
  background-clip: padding-box;
  border-radius: 30px;
  padding: 20px 0;
}

.link-black {
  color: #000;
}
.boxCarro {
  width: 98%;
  background-color: #fff;
  border-radius: 10px;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 10px;
  margin-right: 15px;
  margin-right: 10px;
  color: #747474;
  i {
    font-size: 46px;
  }
  .titulo {
    font-size: 18px;
    text-transform: uppercase;
    font-family: 'poppinsregular';
    color: #747474;
    span {
      display: block;
      font-size: 22px;
      font-family: 'poppinsbold';
      margin-top: -10px;
    }
  }
  .quantidade {
    font-size: 50px;
    font-family: 'poppinsbold';
    text-align: center;
    margin: 0 auto;
    border-bottom: 7px solid #747474;
  }
  .borderSuccess {
    border-bottom-color: #28a745;
  }
  .borderDanger {
    border-bottom-color: #dc3545;
  }
  .borderWarning {
    border-bottom-color: #ffc107;
  }
  .borderDark {
    border-bottom-color: #343a40;
  }
}
.form-control,
.Password__field,
select.custom-select,
.vs__dropdown-toggle {
  border-radius: 0px !important;
}

.btn-copy-link {
  height: calc(1.5em + 0.75rem + 7px) !important;
  -webkit-border-top-right-radius: 14px !important;
  -webkit-border-bottom-right-radius: 14px !important;
  -moz-border-radius-topright: 14px !important;
  -moz-border-radius-bottomright: 14px !important;
  border-top-right-radius: 14px !important;
  border-bottom-right-radius: 14px !important;
}
</style>
