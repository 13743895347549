<template>
  <div>
    <div class="containerMenu">
      <div class="menuBox" v-for="item in menuPrimario" :key="item.id">
        <router-link class="link-black" :to="item.action">
          {{ item.texto1 }} <span>{{ item.texto2 }}</span>
          <img :src="returnIcone(item.icone)" />
        </router-link>
      </div>
    </div>
    <div class="continuacaoMenu">
      <div class="seta text-center">
        <i
          id="icon"
          class="fa fa-chevron-down"
          @click="menuSecundarioOpen = !menuSecundarioOpen"
          :class="menuSecundarioOpen == true ? 'open' : ''"
        ></i>
      </div>
      <div
        class="containerMenuExtra"
        v-if="menuSecundarioOpen"
        :class="menuSecundarioOpen == true ? '' : 'hidden'"
      >
        <div
          class="menuBox"
          v-for="item in showMenuSecundario()"
          :key="item.id"
        >
          <router-link class="link-black" :to="item.action">
            <img :src="returnIcone(item.icone)" class="iconeMenu" />
            <span>{{ item.texto }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from 'jsonwebtoken';
export default {
  props: {
    decoded: Object,
    openMenuLateral: Boolean,
  },
  data() {
    return {
      idEmpresa: null,
      width: { width: '0px' },
      menuSecundarioOpen: false,
      menuPrimario: [
        {
          action: '/Propostas/EmAberto',
          texto1: 'Fazer',
          texto2: 'Vistoria',
          icone: 'VistoriaRapido.png',
        },
        {
          action: '/Boletos',
          texto1: 'Pagar',
          texto2: 'Mensalidade',
          icone: 'PagamentoRapido.png',
        },
        {
          action: '/Assistencia',
          texto1: 'Assistência',
          texto2: '24 horas',
          icone: 'ReboqueRapido.png',
        },
      ],
      menuSecundario: [
        {
          show: true,
          action: '/Veiculos',
          texto: 'Veículos',
          icone: 'VeiculosMenu.png',
        },
        {
          show: true,
          action: '/Boletos',
          texto: 'Financeiro',
          icone: 'FinanceiroMenu.png',
        },
        {
          show: true,
          action: '/CartaoAssociado',
          texto: 'Cartão Virtual',
          icone: 'CartaoMenu.png',
        },
        {
          show: true,
          action: '/Regulamento',
          texto: 'Regulamento',
          icone: 'RegulamentoMenu.png',
        },
        {
          show: true,
          action: '/Termos',
          texto: 'Termos',
          icone: 'TermosMenu.png',
        },
        {
          show: true,
          action: '/Propostas/EmAberto',
          texto: 'Propostas',
          icone: 'CotacoesMenu.png',
        },
        // {
        //   show: true,
        //   action: '/Eventos',
        //   texto: 'Eventos',
        //   icone: 'EventosMenu.png',
        // },
        {
          show: false,
          action: '/IndicarAmigos',
          texto: 'Indicar Amigo',
          icone: 'AmigoMenu.png',
        },
        {
          show: false,
          action: '/testeCamera',
          texto: 'Loja Virtual',
          icone: 'ComprasMenu.png',
        },
        {
          show: false,
          action: '/Rastreamento',
          texto: 'Rastreamento',
          icone: 'RastreamentoMenu.png',
        },
        {
          show: false,
          action: '/Vantagens',
          texto: 'Vantagens',
          icone: 'DescontoMenu.png',
        },
      ],
    };
  },
  methods: {
    showMenuSecundario() {
      console.log(this.idEmpresa);
      if (this.idEmpresa == 4) {
        return this.menuSecundario;
      } else {
        if (this.idEmpresa == 1) {
          console.log('caiu aqui a');
          return this.menuSecundario.filter(
            (x) => x.show == true && x.texto != 'Eventos',
          );
        } else return this.menuSecundario.filter((x) => x.show == true);
      }
    },
    returnIcone(icone) {
      return require('./../assets/icones/' + icone);
    },
    formataNome(nome) {
      var aux = '';
      if (nome) aux = nome.split(' ')[0];
      return aux;
    },
    verificaToken() {
      const token = localStorage.getItem('user-token');
      if (token) {
        var decoded = jwt.decode(token);
        this.idEmpresa = decoded.id_empresa;
        console.log(this.decoded);
      }
    },
  },
  mounted() {
    this.verificaToken();
  },
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.fa-chevron-down {
  transform: rotate(0deg);
  transition: all 0.75s 0.25s;
}

.fa-chevron-down.open {
  transform: rotate(180deg);
  transition: all 0.75s 0.25s;
}

.containerMenu {
  margin-top: 0px;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  width: 100%;
  padding: 10px 10px 0;
  .menuBox {
    width: 31%;
    background-color: #fff;
    border-radius: 10px;
    align-items: center;
    padding: 5px 0;
    margin-bottom: 10px;
    text-align: center;
    a {
      color: #6a6a6b;
      font-size: 12px;
      span {
        font-weight: bolder;
        font-family: 'robotobold';
        display: block;
        margin-top: -5px;
      }
      .iconeMenu {
        width: 90px;
        height: 40px;
        margin-top: 10px;
      }
    }
  }
}

.continuacaoMenu {
  .seta {
    font-size: 26px;
    line-height: 18px;
  }
}

.containerMenuExtra {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  align-content: flex-end;
  border-top: 5px solid #777;
  border-radius: 5px 5px 0 0;

  .menuBox {
    width: 33.3%;
    background-color: #e7e7e7;
    border: 1px solid #ddd;
    align-items: center;
    padding: 5px 0;
    text-align: center;
    a {
      color: #6a6a6b;
      font-size: 12px;
      span {
        display: block;
      }
      .iconeMenu {
        /*display: block;*/
      }
    }
  }
}
</style>
