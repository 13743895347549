import colors from './colors';

export default {
  escondeEmail(email) {
    var maskedEmail = email.replace(/([^@.])/g, '*').split('');
    var previous = '';
    for (let i = 0; i < maskedEmail.length; i++) {
      if (i <= 1 || previous == '.' || previous == '@') {
        maskedEmail[i] = email[i];
      }
      previous = email[i];
    }
    return maskedEmail.join('');
  },
  escondeCelular(celular) {
    var arddd = celular.split(')');
    var ddd = arddd[0] + ')';
    var arrFim = celular.split('-');
    var fimCelular = arrFim[1];
    return ddd + '9****-' + fimCelular;
  },

  /**
   * Existe o Toast
   * @param {object} objToast objeto `this.$bvToast`
   * @param {string} message mensagem do toast
   * @param {string} variant variant do toast
   */
  showToast(objToast, message, variant) {
    objToast.toast(message, {
      title: 'Atenção!',
      solid: true,
      variant,
      toaster: 'b-toaster-top-full',
      appendToast: false,
      autoHideDelay: 4000,
    });
  },

  returnCor(temaCor, chave, comSombra, backgroundTransparent) {
    let rgb = {};

    if (temaCor[chave]) {
      rgb = colors.hexToRgb(temaCor[chave]);
    }

    if (backgroundTransparent) {
      return `background-color: rgba(${rgb.r}, ${rgb.g}, ${rgb.b},0.3);`;
    }

    if (comSombra) {
      return `
        box-shadow: 0 5px 15px rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.9);
        background-color: ${temaCor[chave]};
      `;
    } else {
      return `
        background-color: ${temaCor[chave]};
      `;
    }
  },

  verificaEmpresaBemProtege(idEmpresa) {
    const idsEmpresaBemProtege = this.idsEmpresaBemProtege();
    return idsEmpresaBemProtege.includes(+idEmpresa);
  },

  idsEmpresaBemProtege() {
    return [4,116, 117, 118, 119];
  },

  validateState(veeFields, veeErrors, ref) {
    if (veeFields[ref] && (veeFields[ref].dirty || veeFields[ref].validated)) {
      return !veeErrors.has(ref);
    }
    return null;
  },
  validateClass(veeFields, veeErrors, ref) {
    if (veeFields[ref] && (veeFields[ref].dirty || veeFields[ref].validated)) {
      if (!veeErrors.has(ref) == false) {
        return 'border border-danger ';
      } else {
        return 'border border-success ';
      }
    }
    return null;
  },
  /**
   * @param Object - Objeto que será analisado
   * @returns true para objeto vazio
   */
  isObjectEmpty(value) {
    const isEmpty =
      value && Object.keys(value).length && value.constructor === Object;

    return !isEmpty;
  },
};
