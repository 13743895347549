var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[(_vm.carregando == true)?_c('div',{staticClass:"carregando"},[_c('br'),_c('br'),_c('br'),_vm._v("Carregando.. Aguarde ")]):_vm._e(),_c('Topo',{attrs:{"decoded":_vm.decoded},on:{"OpenMenuLateral":function($event){_vm.menuOpen = true}}}),_c('div',{staticClass:"container p-3 mb-5"},[_c('b-row',[_c('b-col',{attrs:{"cols":"1"}},[_c('router-link',{staticClass:"link-black",attrs:{"tag":"a","to":'/dashboard'}},[_c('i',{staticClass:"fa fa-chevron-left text-success"})])],1),_c('b-col',[_c('h5',{staticClass:"m-4 text-center"},[_vm._v("Cobranças")])])],1),_c('b-list-group',[(_vm.boletos.length == 0)?_c('b-list-group-item',{attrs:{"variant":"danger"}},[_vm._v("Nenhuma Cobrança Encontrada")]):_vm._e(),_vm._l((_vm.boletos),function(item){return _c('b-list-group-item',{key:item.id,attrs:{"variant":("" + (item.situacao_boleto == 'BAIXADO' ||
            item.situacao_boleto == 'Finalizado'
              ? 'success'
              : ''))}},[_vm._v(" "+_vm._s(item.forma_pagamento)+" - "+_vm._s(item.tipo_boleto)+" ("+_vm._s(_vm._f("currency")(item.valor_boleto))+") - "+_vm._s(item.situacao_boleto)+" "),_c('br'),_c('b-badge',{attrs:{"variant":_vm.retornaClasseVencimento(
                item.data_vencimento,
                item.situacao_boleto
              )}},[_vm._v("Vencimento: "+_vm._s(_vm.formataData(item.data_vencimento)))]),_c('br'),(item.data_pagamento)?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("Pagamento "+_vm._s(_vm.formataData(item.data_pagamento)))]):_vm._e(),(
              item.situacao_boleto != 'BAIXADO' &&
              item.situacao_boleto != 'Finalizado' &&
              _vm.validaVencimento(item.data_vencimento)
            )?_c('div',{staticClass:"mt-2"},[(item.forma_pagamento == 'Boleto' || !item.forma_pagamento)?_c('div',[_c('label',[_vm._v("Linha digitável:")]),_c('Br'),_c('div',{staticClass:"input-group mb-3"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Linha digitável","aria-label":"Linha digitável","aria-describedby":"button-addon2"},domProps:{"value":item.linha_digitavel}}),_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-dark",attrs:{"type":"button","id":"button-addon2"},on:{"click":function($event){return _vm.copiarText(item.linha_digitavel)}}},[_c('i',{staticClass:"fa fa-copy"})])]),_c('div',{staticClass:"input-group-append"},[_c('a',{staticClass:"btn btn-success text-white",attrs:{"target":"_blank","href":("/Boleto/" + (item.idBoleto) + "/" + (item.nosso_numero)),"id":"button-addon2"}},[_c('i',{staticClass:"fa fa-download"})])])])],1):_vm._e(),(item.forma_pagamento == 'Pix')?_c('div',[_c('VueQr',{attrs:{"text":item.pixQrCode}}),_c('div',{staticClass:"input-group mb-3"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"QR Code","aria-label":"QR Code","aria-describedby":"button-addon2"},domProps:{"value":item.pixQrCode}}),_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-dark",attrs:{"type":"button","id":"button-addon2"},on:{"click":function($event){return _vm.copiarText(item.pixQrCode, 'Pix')}}},[_c('i',{staticClass:"fa fa-copy"})])])])],1):_vm._e()]):(
              item.situacao_boleto != 'BAIXADO' &&
              item.situacao_boleto != 'Finalizado' &&
              item.situacao_boleto != 'CANCELADO' &&
              !_vm.validaVencimento(item.data_vencimento)
            )?_c('div',[_c('button',{staticClass:"btn btn-success btn-block mt-2",attrs:{"type":"button","id":"button-addon2"},on:{"click":function($event){return _vm.reativarPorBoleto(item)}}},[_vm._v(" Gerar Reativação ")])]):_vm._e()],1)})],2),_c('div',{staticClass:"m-5"},[_vm._v(" ")])],1),_c('MenuRodape',{attrs:{"decoded":_vm.decoded},on:{"OpenMenuLateral":function($event){_vm.menuOpen = true}}})],1),_c('MenuLateral',{attrs:{"decoded":_vm.decoded,"openMenuLateral":_vm.menuOpen},on:{"closeMenuLateral":function($event){_vm.menuOpen = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }