<template>
  <div class="metas p-2 mt-1 mb-3 text-white">
    <h3 class="m-0 ml-2 p-0">Status</h3>
    <b-row>
      <b-col cols="10" class="pr-0 d-flex align-items-end">
        <b-select
          :options="listaStatus"
          name="Tipo de CNH"
          id="Tipo"
          v-model="statusSelect"
          placeholder="Abertas"
          @change="disparaFiltro"
        ></b-select>
      </b-col>
      <b-col cols="2">
        <button
          @click="mostralModalFiltro = true"
          type="button"
          class="btn btn-primary btn-copy-link"
        >
          <i class="fa fa-filter"></i>
        </button>
      </b-col>
    </b-row>
    <b-modal
      v-model="mostralModalFiltro"
      :active.sync="mostralModalFiltro"
      hide-footer
      centered
      title="Filtrar cotações por:"
      class="modalFiltro"
    >
      <div class="container-filtro">
        <b-form-group id="input-group-2" label label-for="input-2">
          <p>Placa</p>
          <the-mask
            mask="AAA-#XX#"
            style="text-transform: uppercase"
            id="input-2"
            class="form-control mb-2"
            v-model="formFiltro.placa"
            name="Placa"
            :masked="true"
          />
          <p>Nome</p>
          <b-form-input class="mb-2" v-model="formFiltro.nome"></b-form-input>
          <p>Email</p>
          <b-form-input
            id="Email"
            name="Email"
            class="mb-2"
            v-model="formFiltro.email"
            v-validate="{ email: true, required: false }"
            :state="validateState('Email')"
          ></b-form-input>
          <p>Telefone/Celular</p>
          <the-mask
            :mask="['(##) ####-####', '(##) #####-####']"
            style="text-transform: uppercase"
            id="Celular"
            name="Celular"
            class="form-control mb-2"
            v-model="formFiltro.telefone"
            :masked="true"
            :state="validateState('Celular')"
            :class="validateClass('Celular')"
          />
          <p>CPF/CNPJ</p>
          <the-mask
            type="text"
            class="form-control mb-2"
            v-model="formFiltro.cpfcnpj"
            v-validate="{ required: false }"
            :mask="['###.###.###-##', '##.###.###/####-##']"
            id="CPF/CNPJ"
            name="CPF/CNPJ"
            :masked="true"
            :state="validateState('CPF/CNPJ')"
            :class="validateClass('CPF/CNPJ')"
          ></the-mask>
          <p>Código</p>
          <b-form-input class="mb-2" v-model="formFiltro.codigo"></b-form-input>
        </b-form-group>
      </div>

      <b-row>
        <b-col class="d-flex justify-content-end mt-3">
          <button
            @click="resetaFiltros"
            type="button"
            class="btn btn-danger btn-copy-link mr-2 w-40px"
          >
            <i class="fa fa-trash"></i>
          </button>
          <b-button @click="emitirFiltroCotacoes()" pill variant="primary"
            >Filtrar</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<!-- eslint-disable -->
<script>
import service from '../services';
import locale from './../locale';
export default {
  name: 'DashBoard',
  props: {
    msg: String,
    aberta: Number,
  },
  watch: {
    aberta: function (newValue) {
      newValue > 0 && this.trocarStatusParaAbertas();
    },
  },
  data() {
    return {
      value: 80,
      max: 100,
      listaStatus: [],
      statusSelect: null,
      mostralModalFiltro: false,
      formFiltro: {
        placa: null,
        nome: null,
        cpfcnpj: null,
        email: null,
        telefone: '',
        codigo: null,
      },
    };
  },
  methods: {
    disparaFiltro() {
      //console.log(this.statusSelect);
      this.$emit('filtroStatus', this.statusSelect);
    },
    emitirFiltroCotacoes() {
      this.$validator.validateAll().then(async (result) => {
        if (!result) {
          this.popToast();
          return;
        } else {
          this.$emit('FiltroCotacoesEmitido', this.formFiltro);
          this.mostralModalFiltro = false;
        }
      });
    },
    resetaFiltros() {
      this.formFiltro = {
        placa: null,
        nome: null,
        cpfcnpj: null,
        email: null,
        telefone: '',
        codigo: null,
      };
      this.mostralModalFiltro = false;
      this.$emit('FiltroCotacoesEmitido', this.formFiltro);
    },
    buscaDadosSelects(model, array, campoDescricao, campoPK) {
      service.getAll('g', {}, `${model}/list/`).then((res) => {
        res.data.forEach((element) => {
          this[array].push({
            value: element[campoPK],
            text: element[campoDescricao],
          });
        });
        this[array].unshift({ value: null, text: 'Abertas' });
      });
    },
    trocarStatusParaAbertas() {
      this.statusSelect = null;
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    validateClass(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        if (!this.veeErrors.has(ref) == false) {
          return 'border border-danger ';
        } else {
          return 'border border-success ';
        }
      }
      return null;
    },
    popToast() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;

      var msg = '';
      var arrMsg = this.$validator.errors.all();
      var arrAux = [];
      arrMsg.forEach((element) => {
        arrAux.push(h('p', {}, element));
      });
      // Create the message
      const vNodesMsg = h('p', { class: ['text-center', 'mb-0'] }, arrAux);

      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: 'Atenção!',
        solid: true,
        variant: 'danger',
        toaster: 'b-toaster-top-full',
        appendToast: false,
        autoHideDelay: 2500,
      });
    },
  },
  mounted() {
    this.$validator.localize('en', locale);
    this.buscaDadosSelects(
      'domStatusCotacao',
      'listaStatus',
      'nomeStatus',
      'idStatus',
    );
  },
  components: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.metas {
  font-size: 11px;
  background-color: black;
  border-radius: 10px;
}
.iconeChart {
  width: 50px;
  height: 50px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
  background-color: slategrey;
}
.avatar {
  width: 80px;
  height: 80px;
  /*position: relative;*/
}
.nota {
  margin-top: -10px;
  /* clear: both;
  position: relative;*/
}
.metas h3 {
  font-size: 15px;
  font-weight: bold;
}

.metas h5 {
  font-size: 11px;
}

.modalFiltro {
  position: relative;
  z-index: 9999 !important;
}
</style>
<style lang="scss" scoped>
.container-filtro {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  p {
    margin: 0;
  }
}
</style>
