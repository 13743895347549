<template>
  <div class="listaCotacoes">
    <div v-if="cotacoes.length == 0" class="alert alert-danger">
      Nenhuma Cotação Encontrada
    </div>
    <swipe-list
      v-else
      ref="list"
      class="card"
      :disabled="!enabled"
      :items="preCotacoes"
      item-key="id"
      :revealed.sync="revealed"
      :item-disabled="({ disabled }) => disabled"
      @closed="setLastEvent('closed', $event)"
      @leftRevealed="setLastEvent('leftRevealed', $event)"
      @rightRevealed="setLastEvent('rightRevealed', $event)"
    >
      <template
        v-slot="{ item, index, revealLeft, revealRight, close, revealed }"
      >
        <!-- item is the corresponding object from the array -->
        <!-- index is clearly the index -->
        <!-- revealLeft is method which toggles the left side -->
        <!-- revealRight is method which toggles the right side -->
        <!-- close is method which closes an opened side -->
        <div ref="content" class="card-content" @click="itemClick(item)">
          <b-row>
            <b-col cols="1">
              <span :class="`badge badge-${getClasStatus(item.status)}`">
                <!--i :class="`${getIconeTipo(item.tipo)} `"></i-->
                <font-awesome-icon
                  :icon="['fa', `${getIconeTipo(item)}`]"
                  class="font14"
                />
              </span>
            </b-col>
            <b-col>
              {{ formataNome(item.nome) }}
              <div class="text-secondary">
                {{
                  (item.jsonRetorno.dadosPlaca &&
                    item.jsonRetorno.dadosPlaca.placa) ||
                  ''
                }}
              </div>
            </b-col>
            <!--b-col cols="3" class="text-center padding-0">
                {{somaValorTotal(item.produtos) | currency}}
                <div class="text-secondary">{{somaValorTotalMes(item.produtos) | currency}} / mês</div>
              </b-col-->
            <b-col cols="4" class="text-center padding-0">
              {{ item.nomeStatus }}
              <div class="text-secondary">{{ exibeDias(item) }}</div>
            </b-col>
            <b-col
              cols="1"
              v-if="
                decoded.tipo != 'Associado' || item.nomeStatus == 'Finalizada'
              "
            >
              <font-awesome-icon icon="chevron-left" />
            </b-col>
          </b-row>
          <b-row class="mt-3" v-if="item.exibeDetalhe == true">
            <b-col cols="1"></b-col>
            <b-col class="pb-2">
              <b-row>
                <b-col cols="4">
                  <b>id:</b>
                </b-col>
                <b-col>{{ item.idPreCotacao }}</b-col>
              </b-row>
              <b-row v-if="item.dataConversao">
                <b-col cols="4">
                  <b>Data da conversão:</b>
                </b-col>
                <b-col>{{
                  item.dataConversao.slice(0, 10).split('-').reverse().join('/')
                }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="4">
                  <b>Telefone:</b>
                </b-col>
                <b-col>{{ item.celular }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="4">
                  <b>Email:</b>
                </b-col>
                <b-col>{{ item.email }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="4">
                  <b>Marca/Modelo:</b>
                </b-col>
                <b-col>{{
                  item.jsonRetorno.dadosPlaca &&
                  item.jsonRetorno.dadosPlaca.marcaModelo
                }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="4">
                  <b>Grupo:</b>
                </b-col>
                <b-col>{{ item.jsonRetorno.grupoSeleciondo.label }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="4">
                  <b>Estado:</b>
                </b-col>
                <b-col>{{ item.estado }}</b-col>
              </b-row>
              <b-row>
                <b-col cols="4">
                  <b>Cidade:</b>
                </b-col>
                <b-col>{{ item.cidade }}</b-col>
              </b-row>
              <!-- <b-row v-if="item.dataVistoria">
                  <b-col cols="4">
                    <b>Vistoria:</b>
                  </b-col>
                  <b-col>{{ formataData(item.dataVistoria) }}</b-col>
                </b-row>
                <b-row v-if="item.motivoReprovacaoGeral">
                  <b-col class="text-center mt-2">
                    <p class="alert alert-danger">
                      {{ item.motivoReprovacaoGeral }}
                    </p>
                  </b-col>
                </b-row> -->
            </b-col>
          </b-row>
          <!-- <b-row
              class="mt-2"
              v-if="
                decoded.tipo == 'Associado' &&
                item.nomeStatus != 'Aguardando Aprovação' &&
                item.nomeStatus != 'Finalizada'
              "
            >
              <b-col
                @click="aceitarProposta(item, false)"
                cols="6"
                class="bg-light text-center box"
              >
                <div class="iconeChart bg-success">
                  <img src=" https://bboneapp.s3.amazonaws.com/mobile/car.png" />
                </div>
                <span v-if="item.nomeStatus == 'Em Aberto'"
                  >Realizar Vistoria</span
                >
                <span v-if="item.nomeStatus == 'Pendente'"
                  >Corrigir Vistoria</span
                >
              </b-col>
              <b-col
                @click="verDetalhes(item)"
                cols="6"
                class="bg-light text-center box"
              >
                <div class="iconeChart">
                  <i class="fa fa-search-plus text-white"></i>
                </div>
                <span>Detalhes</span>
              </b-col>
            </b-row> -->
        </div>
      </template>

      <template v-slot:right="{ item, close, index }">
        <div
          v-if="item.idPropostaConvertida === null"
          class="swipeout-action purple"
          @click="converter(item.idPreCotacao)"
        >
          <i class="fa fa-copy"></i>
        </div>
        <!-- <div
            
            class="swipeout-action blue"
            @click="verDetalhes(item)"
          >
            
            <i class="fa fa-search-plus"></i>
          </div> -->
        <!-- <div
            
            class="swipeout-action green"
            title="Whatsapp"
          >
            
            <a target="_blank" class="text-white" :href="returnLinkWhats(item)">
              <i class="fa fa-whatsapp"></i>
            </a>
          </div> -->
      </template>
    </swipe-list>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { SwipeList, SwipeOut } from 'vue-swipe-actions';
import jwt from 'jsonwebtoken';
import moment from 'moment';
import { isMobile, isBrowser, deviceDetect } from 'mobile-device-detect';
export default {
  name: 'DashBoard',
  props: ['msg', 'cotacoes', 'filtro'],
  data() {
    return {
      preCotacoes: [],
      decoded: {},
      carregando: false,
      enabled: true,
      page: 0,
      revealed: {},
      lastEventDescription: '',
      mockSwipeList: [[]],
    };
  },
  components: { SwipeOut, SwipeList },
  watch: {
    cotacoes() {
      this.atribuiCotacoes();
    },
  },
  methods: {
    copiarText(chave) {
      //O texto que será copiado
      const texto = 'https://adm.evogerencial.com.br/proposta/' + chave;
      //Cria um elemento input (pode ser um textarea)
      let inputTest = document.createElement('input');
      inputTest.value = texto;
      //Anexa o elemento ao body
      document.body.appendChild(inputTest);
      //seleciona todo o texto do elemento
      inputTest.select();
      //executa o comando copy
      //aqui é feito o ato de copiar para a area de trabalho com base na seleção
      document.execCommand('copy');
      //remove o elemento
      document.body.removeChild(inputTest);
      this.$bvToast.toast(
        `Link da Proposta copiado para a área de Transfêrencia`,
        {
          title: 'Atenção!',
          solid: true,
          variant: 'success',
          toaster: 'b-toaster-top-full',
          appendToast: false,
          autoHideDelay: 2500,
        },
      );
    },
    returnLinkWhats(item) {
      var nome = item.nome.split(' ')[0];
      var celular = item.celular
        ? item.celular
        : item.telefone
            .replace('(', '')
            .replace(')', '')
            .replace(' ', '')
            .replace('-', '');
      var texto = `Olá ${nome}, tudo bem? A cotação da associação ${item.empresa} para proteger seu veículo foi gerada, você consegue aceita-la e fazer sua vistoria tudo por lá, acesse https://adm.evogerencial.com.br/proposta/${item.chave}`;
      var textoEncode = encodeURI(texto);

      var link = '';
      //if (isBrowser == true)
      link = `https://api.whatsapp.com/send?phone=55${celular}&text=${textoEncode}`;
      //else link = `whatsapp://send?phone=55${celular}&text=${textoEncode}`;

      return link;
    },
    disparaLink(item) {
      console.log(item);
      console.log(isMobile, isBrowser);

      var nome = item.nome.split(' ')[0];
      var celular = item.telefone
        .replace('(', '')
        .replace(')', '')
        .replace(' ', '')
        .replace('-', '');
      var texto = `Olá ${nome}, tudo bem? A cotação da associação ${item.empresa} para proteger seu veículo foi gerada, você consegue aceita-la e fazer sua vistoria tudo por lá, acesse https://adm.evogerencial.com.br/proposta/${item.chave}`;
      var textoEncode = encodeURI(texto);

      var link = '';
      if (isBrowser == true)
        link = `https://api.whatsapp.com/send?phone=55${celular}&text=${textoEncode}`;
      else link = `whatsapp://send?phone=55${celular}&text=${textoEncode}`;
      console.log(link);
      //link = `whatsapp://send?phone=55${celular}&text=${textoEncode}`;
      window.open(link, '_blank');
      //console.log("tentei abrir popup");
      //window.location = link;
    },
    converter(id) {
      this.$router.push({
        path: `/ConversaoPreCotacao/${id}`,
      });
    },
    somaValorTotalMes(produtos) {
      var total = 0;
      produtos.forEach((element) => {
        console.log(element);
        if (element.isMensal == true) total = total + element.valorFinal;
      });
      return total;
    },
    somaValorTotal(produtos) {
      var total = 0;
      produtos.forEach((element) => {
        if (element.isMensal != true) total = total + element.valorFinal;
      });
      return total;
    },
    verDetalhes(item) {
      this.$emit('DetalheCotacao', item);
    },
    aceitarProposta(item, isConsultor) {
      var queryString = '';
      if (isConsultor == true) {
        queryString = '?isConsultor=true';
      }
      this.carregando = true;
      if (item.nomeStatus == 'Pendente')
        this.$router.push({
          path: `/AceiteCotacao/${item.chave}/8/0${queryString}`,
        });
      else
        this.$router.push({
          path: `/AceiteCotacao/${item.chave}${queryString}`,
        });
    },
    atualizarProdutosCotacao(item) {
      this.carregando = true;
      this.$router.push({
        path: `/AtualizarProdutosCotacao/${item.chave}`,
      });
    },
    exibeDias(item) {
      if (item.nomeStatus == 'Em Aberto') {
        if (moment().diff(item.dataCotacao, 'days') == 1) {
          return '1 dia';
        } else {
          return `${moment().diff(item.dataCotacao, 'days')} dias`;
        }
      }
    },
    formataData(data) {
      return moment(data).format('DD/MM/YYYY HH:mm');
    },
    getClasStatus(status) {
      return status.split(' ')[0].replace(' ', '');
    },
    getIconeTipo(item) {
      if (item.jsonRetorno.grupoSeleciondo.idTipoFipe == 1) return 'car';
      if (item.jsonRetorno.grupoSeleciondo.idTipoFipe == 2) return 'motorcycle';
      if (item.jsonRetorno.grupoSeleciondo.idTipoFipe == 3) return 'truck';
    },
    formataNome(nome) {
      var aux = nome.split(' ');
      var novoNome = '';
      if (aux[1]) novoNome = aux[0] + ' ' + aux[1];
      else novoNome = aux[0];
      return novoNome;
    },
    dd(v) {
      console.log(v.target);
    },
    revealFirstRight() {
      this.$refs.list.revealRight(0);
    },
    revealFirstLeft() {
      this.$refs.list.revealLeft(0);
    },
    closeFirst() {
      this.$refs.list.closeActions(0);
    },
    closeAll() {
      this.$refs.list.closeActions();
    },
    remove(item) {
      this.$set(
        this.mockSwipeList,
        this.page,
        this.mockSwipeList[this.page].filter((i) => i !== item),
      );
    },
    setLastEvent(name, { item, index }) {
      this.lastEventDescription = {
        name,
        index,
        id: item.id,
      };
    },
    itemClick(e) {
      this.preCotacoes = this.preCotacoes.map((c) =>
        e.idPreCotacao === c.idPreCotacao
          ? {
              ...c,
              exibeDetalhe: !c.exibeDetalhe,
            }
          : c,
      );
      console.log(e, 'item click');
    },
    fbClick(e) {
      console.log(e, 'First Button Click');
    },
    sbClick(e) {
      console.log(e, 'Second Button Click');
    },
    // keyboard
    onKeyDown(e) {
      if (e.keyCode !== 16) return;
      this.enabled = false;
    },
    onKeyUp(e) {
      if (e.keyCode !== 16) return;
      this.enabled = true;
    },
    openDecoded() {
      console.log(this.cotacoes);
      const token = localStorage.getItem('user-token');
      if (token) {
        this.decoded = jwt.decode(token);
        if (this.decoded.tipo == 'Associado') {
          if (this.cotacoes[0]) this.cotacoes[0].exibeDetalhe = true;
        }
      }
    },
    atribuiCotacoes() {
      this.preCotacoes = this.cotacoes;
    },
  },
  mounted() {
    this.openDecoded();
    this.atribuiCotacoes();
  },
};
</script>
<style>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
.listaCotacoes {
  padding-bottom: 100px;
}
.b-toaster {
  z-index: 99999 !important;
}

.badge-Convertida {
  background-color: #44c0f0;
  padding-right: 2px;
  width: 25px;
  color: #fff;
}

.badge-Não {
  background-color: #2f3666;
  padding-right: 2px;
  width: 25px;
  color: #fff;
}

.padding-0 {
  padding-right: 0;
  padding-left: 0;
}

.font14 {
  font-size: 14px;
}
/* app specific styles */
.swipeout-action {
  display: flex;
  align-items: center;
  padding: 0 1.4rem;
  cursor: pointer;
  left: 0;
}
/* https://developer.apple.com/design/human-interface-guidelines/ios/visual-design/color/ */
.swipeout-action.blue {
  color: white;
  background-color: rgb(0, 122, 255);
}
.swipeout-action.blue:hover {
  background-color: darken(rgb(0, 122, 255), 5%);
}
.swipeout-action.purple {
  color: white;
  background-color: rgb(88, 86, 214);
}
.swipeout-action.purple:hover {
  background-color: darken(rgb(88, 86, 214), 5%);
}
.swipeout-action.red {
  color: white;
  background-color: rgb(255, 59, 48);
}
.swipeout-action.red:hover {
  background-color: darken(rgb(255, 59, 48), 5%);
}
.swipeout-action.black {
  color: white;
  background-color: rgb(41, 41, 41);
}
.swipeout-action.black:hover {
  background-color: darken(rgb(41, 41, 41), 5%);
}
.swipeout-action.green {
  color: white;
  background-color: rgb(76, 217, 100);
}
.swipeout-action.green:hover {
  background-color: darken(rgb(76, 217, 100), 5%);
}

.swipeout-action.orange {
  color: white;
  background-color: rgb(240, 141, 12);
}
.swipeout-action.orange:hover {
  background-color: darken(rgb(240, 141, 12), 5%);
}

.swipeout-list-item {
  flex: 1;
  border-bottom: 1px solid lightgray;
}
.swipeout-list-item:last-of-type {
  border-bottom: none;
}
.card {
  width: 100%;
  background-color: white;
  border-radius: 3px;
  box-shadow: none;
  border: 1px solid #e7e7e7;
}
.card-content {
  padding: 1rem;
}
.transition-right {
  transform: translate3d(100%, 0, 0) !important;
}
.transition-left {
  transform: translate3d(-100%, 0, 0) !important;
}
.carregando {
  color: #fff;
  position: fixed;
  margin: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 99999;
  text-align: center;
  padding-top: 50%;
}

.iconeChart {
  width: 60px;
  height: 60px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
  background-color: #999;
  border-radius: 50%;
  padding: 10px;
  margin: 10px 33%;
}
.iconeChart img {
  width: 100%;
}

.iconeChart i {
  font-size: 28px;
}
.box {
  box-model: border-box;
  border: 10px solid transparent;
  background-clip: padding-box;
  border-radius: 30px;
  padding: 20px 0;
}

.linkbranco {
  color: #fff;
}
</style>
